import { QuotaLevels } from "../enums/reservation.enum";
import { IOfferedSubjectByType } from "../types";

export const normalizeText = (str: string) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const getQuotaColor = (quotaAvailability: number) => {
  switch (true) {
    case quotaAvailability === QuotaLevels.HIGH:
      return "green";
    case quotaAvailability === QuotaLevels.MID:
      return "orange";
    case quotaAvailability === QuotaLevels.LOW:
      return "red";
    case quotaAvailability === QuotaLevels.NONE:
      return "gray";
    default:
      return "gray";
  }
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseHours = (time: number) => {
  const hour = String(time).split("");
  const minutes = hour.splice(-2).join("");
  return `${hour.join("")}:${minutes}`;
};

export function transformHorariosData(data: IOfferedSubjectByType[]): { titulo: string; detalle: any[] }[] {
  /* -------------------------------------------------------------------------- */
  /*                               ORDER FUNCTIONS                              */
  /* -------------------------------------------------------------------------- */
  
  const orderProfesores = (profesores: any[]) => {
    const ordenCargos = ["Profesor", "Profesor Adjunto", "Auxiliar", "Ayudante", "Tesis", "Otro", null];
    let listado:string[] = []

    for (let i = 0; i < ordenCargos.length; i++) {
      const filtrados = profesores.filter(profe=>profe.split("|")[1].trim()===ordenCargos[i]) 
      if(filtrados.length>0){
        const ordenAlfabetico = filtrados.sort();
        listado = listado.concat(ordenAlfabetico)
      }
    }

    return listado 
  };
  
  const orderHorariosPorTipoDeClase = (horarios: any[]) => {
    function comparaTipoClase(a: { titulo: string }, b: { titulo: string }) {
      const orden = ["Teórica", "Teórica-Práctica", "Práctica 1", "Práctica 2", "Práctica 3", "Apoyo"];
      const indiceA = orden.indexOf(a.titulo);
      const indiceB = orden.indexOf(b.titulo);
      return indiceA - indiceB;
    }

    return horarios.sort(comparaTipoClase);
  };
  /* -------------------------------------------------------------------------- */
  const horarios: { titulo: string; detalles: any[] }[] = [];
  const profesoresSet = new Set();

  data.forEach((section: any) => {
    const { subjectType, subjectInfo } = section;
    const titulo = subjectType;

    const detalles = subjectInfo.map((info: any) => {
      const { dia, desde, hasta, profesores, rol, id, aula } = info.values;
      profesores.forEach((profesor: any) => profesoresSet.add(profesor + "|" + rol));
      return { dia, desde, hasta, id, aula };
    });
    const detallesSinRepetidos = detalles.filter(
      (d: any, index: any, self: any[]) => index === self.findIndex((c) => c.id === d.id && c.dia === d.dia && c.desde === d.desde)
    );
    if (titulo !== null) {
      horarios.push({ titulo, detalles: detallesSinRepetidos });
    }
  });

  const profesoresArray = Array.from(profesoresSet);
  /* -------------------------------------------------------------------------- */
  /*                         ORDER PROFESORES Y HORARIOS                        */
  /* -------------------------------------------------------------------------- */
  const profesoresOrdenados = orderProfesores(profesoresArray);
  const horariosOrdenados = orderHorariosPorTipoDeClase(horarios);
  /* -------------------------------------------------------------------------- */
  horariosOrdenados.unshift({ titulo: "Profesor", detalles: profesoresOrdenados });

  const parsedResult: any[] = [];

  horariosOrdenados.forEach((item: any) => {
    if (item.titulo === "Profesor") {
      parsedResult.push({
        titulo: "Profesor",
        detalles: profesoresOrdenados.map((persona: any) => {
          return { nombre: persona.split("|")[0], rol: persona.split("|")[1] };
        }),
      });
    } else {
      parsedResult.push(item);
    }
  });

  return parsedResult;
}

export function createSubjectSlug(text: string) {
  if (!text) {
    return "";
  }

  let slug = "";
  if (text && text.includes("-")) {
    const nombre = text
      .split("-")[0]
      .split(" ")
      .filter((t) => t !== "");

    const seccion = text
      .split("-")[1]
      .split(" ")
      .filter((t) => t !== "")[0];

    slug = nombre.map((word) => word.charAt(0)).join("") + " " + seccion;
  } else {
    const nombre = text.split(" ").filter((t) => t !== "");
    slug = nombre.map((word) => word.charAt(0)).join("");
  }

  return slug;
}
