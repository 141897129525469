import "./SelectCarrera.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStudent,
  setCarrearActual,
  setStudentMostrarHorarios,
  setStudentPrioridad,
  setToken,
} from "../../reduxSlices/studentSlice";
import SlDropdown from "@shoelace-style/shoelace/dist/react/dropdown";
import SlMenu from "@shoelace-style/shoelace/dist/react/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/react/menu-item";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import { CarreraItem, EstadoLoginStudent } from "../../types";
import { useNavigate } from "react-router-dom";
import { postSeleccionaCarrera } from "../../Api/authentication";

export default function SelectCarrera() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { carreraActual, listadoCarreras } = useSelector(selectStudent);

  async function seleccionaCarrera(idAlu: number, nombreCarrera: string) {
    const res: EstadoLoginStudent = await postSeleccionaCarrera(idAlu);
    const { token, mostrarHorarios } = res;

    dispatch(setCarrearActual(nombreCarrera));
    localStorage.removeItem("CUPO_CURSOS_SYNCED");
    ingresarEnApp(token, mostrarHorarios, idAlu);
  }

  async function ingresarEnApp(
    token: string,
    mostrarHorarios: boolean,
    idAP?: number
  ) {
    localStorage.setItem("token", token);
    dispatch(setToken(token));
    if (idAP) localStorage.setItem("idAP", JSON.stringify(idAP));
    if (mostrarHorarios) dispatch(setStudentMostrarHorarios(mostrarHorarios));
    navigate(0);
  }

  return (
    <div className="select-carrera-container">
      {carreraActual && <p>{carreraActual}</p>}
      {listadoCarreras.length > 1 && (
        <SlDropdown>
          <SlIconButton slot="trigger" className="list" name="chevron-down" label="Menu"></SlIconButton>
          <SlMenu style={{ fontSize: "12px" }}>
            {listadoCarreras.map((item: CarreraItem) => (
              <SlMenuItem
                key={item.descripcionCarrera}
                onClick={() => seleccionaCarrera(item.idAlumnoPrograma, item.descripcionCarrera)}
              >
                {carreraActual == item.descripcionCarrera ? (
                  <>
                    <span className="selected-carrera-item">{item.descripcionCarrera}</span>
                    <SlIcon slot="suffix" name="check-lg"></SlIcon>
                  </>
                ) : (
                  <span>{item.descripcionCarrera}</span>
                )}
              </SlMenuItem>
            ))}
          </SlMenu>
        </SlDropdown>
      )}
    </div>
  );
}
