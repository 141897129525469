import "./ButtonInscription.css"
import { LiaSpinnerSolid } from "react-icons/lia";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { selectInscribedSubjects, selectSubcriptionProcessSectionIds } from "../../reduxSlices/subjectsSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

interface BtnProps {
  setOpenModal: (bool: boolean) => void;
  handlTryReserve: (num: number) => void;
  id: number;
  idMateria: string;
}

export default function ButtonInscription({
  setOpenModal,
  handlTryReserve,
  id,
  idMateria,
}: BtnProps) {
  /* ------------------------------ global store ------------------------------ */
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const subcriptionProcessSectionIds = useSelector(selectSubcriptionProcessSectionIds);
  /* ------------------------------- local state ------------------------------ */
  const [isSubInProcess, setIsSubInProcess] = useState(false);
  /* ------------------------------ calculations ------------------------------ */
  const materiasList = inscribedSubjects?.map((s) => s.id) || [];
  const cambioSeccion = materiasList.indexOf(idMateria) > -1;

  /* -------------------------------- Effeccts -------------------------------- */
   useEffect(() => {
     
     const isProcessing = subcriptionProcessSectionIds.indexOf(Number(id)) !== -1;
     if (isProcessing) {
       setIsSubInProcess(true);
      } else {
        setIsSubInProcess(false);
      }
   }, [subcriptionProcessSectionIds]);

   /* -------------------------------- handlers -------------------------------- */

  return (
    <button
      style={{ width: `${cambioSeccion ? "165px" : ""}` }}
      className={`desktop-action-add ${isSubInProcess ? "desktop-action-add-disabled" : ""}`}
      onClick={cambioSeccion ? () => setOpenModal(true) : () => handlTryReserve(id)}
      // onClick={cambioSeccion ? () => alert("Es cambio de seccion") : () => handlTryReserve(id)}
      disabled={isSubInProcess}
    >
      {isSubInProcess ? (
        <>
          <LiaSpinnerSolid className="spinner-animation" /> Procesando
        </>
      ) : (
        <>
          {cambioSeccion ? (
            <>
              <SlIcon name="arrow-left-right"></SlIcon>
              Cambiar sección
            </>
          ) : (
            <>
              <SlIcon name="plus"></SlIcon>
              Inscribirme
            </>
          )}
        </>
      )}
    </button>
  );
}
