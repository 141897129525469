import "./DesktopOfferList.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reserveSection } from "../../Api/reservation";
import SearchInput from "../SearchInput/SearchInput";
import { useToastError, useToastInfo } from "../../hooks/useToast";
import { IOfferedSubject, IQuota, IReserveError, ISelectedSection } from "../../types";
import { normalizeText } from "../../utils";
import { selectStudent, setStudentInicializado } from "../../reduxSlices/studentSlice";
import {
  addSelectedOfferedSubject,
  removeSelectedOfferedSubject,
  selectFilterTagsList,
  selectInscribedSubjects,
  selectOfferedSubjects,
  selectPinnedSubjectsList,
  selectSearchSubjectsValue,
  selectSelectedFilterTagsList,
  selectSelectedOfferedSubjects,
  selectSubjectsQuotas,
  setLoadingRequestInTransit,
  setSelectedFilterTagsList,
} from "../../reduxSlices/subjectsSlice";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import DesktopTab from "../DesktopTab/DesktopTab";
import DesktopSubjectList from "../DesktopSubjectList/DesktopSubjectList";
import {
  selectPanelFilters,
  setInTransitInscriptionRequest,
  setPanelFilters,
} from "../../reduxSlices/globalFlagsSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { NavOptions } from "../NavOptions/NavOptions";

const filterSubjects = (subjects: IOfferedSubject[], searchTerm: string): IOfferedSubject[] => {
  const filteredSubjects = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        const matchesTitle = normalizeText(section.section).includes(normalizeText(searchTerm));
        const matchesProf = section.subjectData.subjectsByType.some((subject) => {
          return subject.subjectInfo.some((info) => {
            return info.values.profesores.some((prof) => {
              return normalizeText(prof).includes(normalizeText(searchTerm));
            });
          });
        });
        return matchesTitle || matchesProf;
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);
  return filteredSubjects;
};

const filterSubjectsByTag = (subjects: IOfferedSubject[], filterTagsList: string[]) => {
  if (!filterTagsList.length) {
    return subjects;
  }
  const subjectsByFilter = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        // Verificar que 'tag' esté definido y sea una cadena
        if (typeof section.tag !== "string") {
          return false;
        }
        // Dividir la cadena 'tag' y limpiar los espacios
        const tags = section.tag.split(",").map((item) => item.trim());
        // Verificar si algún elemento de 'tags' está en 'filterTagsList'
        // console.log(tags.some((item) => filterTagsList.includes(item)));
        return tags.some((item) => filterTagsList.includes(item));
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);

  return subjectsByFilter;
};

const DesktopOfferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector(selectStudent);
  const selectedSubjects = useSelector(selectSelectedOfferedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const panelFilters = useSelector(selectPanelFilters);
  const selectedFilterTagsListStore = useSelector(selectSelectedFilterTagsList);
  const searchSubjectValue = useSelector(selectSearchSubjectsValue);
  const filterTagsList = useSelector(selectFilterTagsList);

  const [filteredSubjects, setFilteredSubjects] = useState<IOfferedSubject[]>([]);
  const [filtro, setFiltro] = useState("OBLIGATORIO");
  const [subjectsInSearch, setSubjectsInSearch] = useState<IOfferedSubject[]>([]);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [reserveError, setReserveError] = useState<IReserveError>({});
  const showError = useToastError();

  const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  /* -------------------------------------------------------------------------- */
  /*                             SET OFERTA if USER                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (offeredSubjects && offeredSubjects.length) {
      dispatch(setStudentInicializado(2));
      const result = offeredSubjects.filter((s) => s.tipoMateria === filtro);
      setFilteredSubjects(result);
    } else {
      dispatch(setStudentInicializado(0));
    }
  }, [offeredSubjects, filtro]);

  /* -------------------------------- Busqueda + Filtros---------------------- */
  useEffect(() => {
    if (filteredSubjects && searchSubjectValue.trim()?.length > 0) {
      const newSubjects = filterSubjects(filteredSubjects, searchSubjectValue);
      const newSubjectsWithFilter = filterSubjectsByTag(newSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjectsWithFilter);
    } else if (filteredSubjects) {
      const newSubjects = filterSubjectsByTag(filteredSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjects);
    }
  }, [searchSubjectValue, selectedFilterTagsListStore, filteredSubjects]);

  const handleScroll = () => {
    setScrollPosition(Number(scrollTargetRef.current?.scrollTop));
  };

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleReserve = async (selectedSection: ISelectedSection) => {
    let reserveResult: any = {};
    try {
      dispatch(setInTransitInscriptionRequest(true));
      if (selectedSection) {
        const results = await reserveSection({
          idC: selectedSection.idC,
          idS: selectedSection.idS,
          idA: selectedSection.idA,
          tim: selectedSection.tim,
          tic: selectedSection.tic,
          idPersona: student.id.toString(),
        });

        reserveResult = results.data;

        if (results.data.responseCode !== 200) {
          dispatch(removeSelectedOfferedSubject(selectedSection.idS));
          setReserveError({
            ...reserveError,
            [selectedSection.idC]: results.data.responseMessage,
          });
          showError(results.data.responseMessage, () =>
            setReserveError((prevState) => {
              const newState = prevState;
              delete newState[selectedSection.idC];
              return newState;
            })
          );
        } else {
          dispatch(addSelectedOfferedSubject(selectedSection.idS));

          await dispatch(setLoadingRequestInTransit(true));
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
      reserveResult = error;
    } finally {
      dispatch(setInTransitInscriptionRequest(false));
    }
    return reserveResult;
  };

  const handleSelectTab = (tipo: string) => {
    if (tipo === "OBLIGATORIO"){
      dispatch(setSelectedFilterTagsList([]));
      dispatch(setPanelFilters(false));
    }
      if (tipo) {
        setFiltro(tipo);
      } else {
        setFiltro("OBLIGATORIO");
      }
  };

  const handleOpenFilter = () => {
    dispatch(setPanelFilters(true));
  };

  const scrollToTop = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollTop = 0;
    }
  };

  const renderSubjects = (subjects: IOfferedSubject[], listadoFiltros: string[], cajaBusqueda: string) => {
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    return (
      <>
        {subjects.length === 0 && (listadoFiltros?.length || cajaBusqueda) && (
          <p>No coinciden materias con la búsqueda o filtros.</p>
        )}
        {subjects.length === 0 && !(listadoFiltros?.length || cajaBusqueda) && <p>No hay materias disponibles.</p>}
        {subjects
          .sort((a: IOfferedSubject, b: IOfferedSubject) => {
            const yearA = a.sections[0]?.year ?? 0;
            const yearB = b.sections[0]?.year ?? 0;

            return yearA - yearB;
          })
          .map((s) => {
            // filtramos si esta inscripto o pineado
            const notPinnedOrIsncribedSections = s.sections.filter(
              (curso) => pinnedSubjectsList.indexOf(curso.id) === -1 && inscribedSubsIds.indexOf(curso.id) === -1
            );
            return (
              <DesktopSubjectList
                key={s.id}
                onReserve={(section: ISelectedSection) => handleReserve(section)}
                selectedSubjects={selectedSubjects}
                error={reserveError}
                subjectId={s.id}
                subjects={notPinnedOrIsncribedSections}
                quotas={subjectsQuotas ? subjectsQuotas : {}}
                title={s.title}
              />
            );
          })}
      </>
    );
  };
  const renderPinnedSubjects = (subjects: IOfferedSubject[]) => {
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    return (
      <>
        {subjects.map((s) => {
          const pinnedSections = s.sections.filter(
            (curso) => pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1
          );

          return (
            <DesktopSubjectList
              key={s.id}
              onReserve={(section: ISelectedSection) => handleReserve(section)}
              selectedSubjects={selectedSubjects}
              error={reserveError}
              subjectId={s.id}
              subjects={pinnedSections}
              quotas={subjectsQuotas ? subjectsQuotas : {}}
              title={s.title}
            />
          );
        })}
      </>
    );
  };

  const countPinnedByType = (subjects: IOfferedSubject[]) => {
    let count = 0;
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    subjects.forEach((s) => {
      s.sections.forEach((curso) => {
        if (pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1) {
          count++;
        }
      });
    });
    return count;
  };

  return (
    <div className="desktop-offer-list-container">
      {filteredSubjects.length === 0 && student.id && offeredSubjects === null ? (
        <Loader text="Cargando" />
      ) : (
        <>
          <div className="desktop-filters-nav">
              <DesktopTab filtro={filtro} handleSelectTab={handleSelectTab} />
              {/* <NavOptions /> */}
            <div className="search-and-filter">
              <SearchInput />
              {filtro !== "OBLIGATORIO" && filterTagsList && filterTagsList?.length > 0 && (
                <button
                  onClick={handleOpenFilter}
                  className={`filters-btn ${selectedFilterTagsListStore?.length ? "filters-btn-active" : ""}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                    <path
                      d="M14.9814 0H1.01854C0.159386 0 -0.31238 1.04052 0.233792 1.73083L5.9521 8.95821C6.10199 9.14766 6.18396 9.38545 6.18396 9.63088V15.7943C6.18396 16.1287 6.33583 16.4434 6.59355 16.6431L8.07226 17.7887C8.7429 18.3083 9.69589 17.8101 9.69589 16.9399V9.63676C9.69589 9.38776 9.78025 9.14674 9.93414 8.95609L15.7598 1.73882C16.3155 1.05041 15.8453 0 14.9814 0Z"
                      fill={`${selectedFilterTagsListStore?.length ? "white" : "#424242"}`}
                    />
                  </svg>
                  {/* <FilterIcon color={`${selectedFilterTagsListStore?.length ? "white" : "#424242"}`} /> */}
                </button>
              )}
              <NavOptions />
            </div>
          </div>
          <div onScroll={handleScroll} ref={scrollTargetRef} className="desktop-filters-listing">
            {pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0 && (
              <div className="desktop-title-2 pinned-title-2">
                <div className="desktop-title-2-first-col">
                  <h2 className="desktop-title-2-text">
                    Materias Fijadas {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}
                  </h2>
                  <span className="list-subjects-counter">{countPinnedByType(filteredSubjects)}</span>
                </div>

                {scrollPosition > 100 && (
                  <SlIcon onClick={scrollToTop} className="go-to-top" name="arrow-bar-up"></SlIcon>
                )}
              </div>
            )}

            {pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0 && (
              <div className="desktop-pinned-subject-list">{renderPinnedSubjects(filteredSubjects)}</div>
            )}

            <div
              className={`desktop-title-2 ${
                pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0
                  ? "offered-title-sub-2"
                  : "offered-title-2"
              }`}
            >
              <div className="desktop-title-2-first-col">
                <h2 className="desktop-title-2-text">
                  Materias {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}
                </h2>
              </div>
              {scrollPosition > 100 && (
                <SlIcon onClick={scrollToTop} className="go-to-top" name="arrow-bar-up"></SlIcon>
              )}
            </div>

            <div className="desktop-offered-subject-list">
              {searchSubjectValue?.length > 0 ||
              (selectedFilterTagsListStore && selectedFilterTagsListStore?.length > 0)
                ? renderSubjects(subjectsInSearch, selectedFilterTagsListStore || [], searchSubjectValue)
                : renderSubjects(filteredSubjects, selectedFilterTagsListStore || [], searchSubjectValue)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface IconProps{
  color:string,
}
function FilterIcon({color}:IconProps) {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path
          d="M7.42053 5.75659C7.42053 5.44593 7.67237 5.19409 7.98303 5.19409H18.8921C19.2028 5.19409 19.4546 5.44593 19.4546 5.75659C19.4546 6.06725 19.2028 6.31909 18.8921 6.31909H7.98303C7.67237 6.31909 7.42053 6.06725 7.42053 5.75659Z"
          fill={color}
        />
        <path          
          d="M1.96655 5.75659C1.96655 5.44593 2.21839 5.19409 2.52905 5.19409H4.34723C4.65789 5.19409 4.90973 5.44593 4.90973 5.75659C4.90973 6.06725 4.65789 6.31909 4.34723 6.31909H2.52905C2.21839 6.31909 1.96655 6.06725 1.96655 5.75659Z"
          fill={color}
        />
        <path          
          d="M6.03531 4.50073C5.41269 4.50073 4.90796 5.00547 4.90796 5.62809C4.90796 6.25071 5.41269 6.75544 6.03531 6.75544C6.65794 6.75544 7.16267 6.25071 7.16267 5.62809C7.16267 5.00547 6.65794 4.50073 6.03531 4.50073ZM3.78296 5.62809C3.78296 4.38415 4.79137 3.37573 6.03531 3.37573C7.27926 3.37573 8.28767 4.38415 8.28767 5.62809C8.28767 6.87203 7.27926 7.88044 6.03531 7.88044C4.79137 7.88044 3.78296 6.87203 3.78296 5.62809Z"
          fill={color}
        />
        <path
          d="M12.1825 10.2616C12.1825 10.5723 11.9307 10.8241 11.62 10.8241H2.52909C2.21843 10.8241 1.96659 10.5723 1.96659 10.2616C1.96659 9.95094 2.21843 9.6991 2.52909 9.6991H11.62C11.9307 9.6991 12.1825 9.95094 12.1825 10.2616Z"
          fill={color}
        />
        <path
          d="M19.4547 10.2616C19.4547 10.5723 19.2029 10.8241 18.8922 10.8241L15.2558 10.8241C14.9452 10.8241 14.6933 10.5723 14.6933 10.2616C14.6933 9.95094 14.9452 9.6991 15.2558 9.6991L18.8922 9.6991C19.2029 9.6991 19.4547 9.95094 19.4547 10.2616Z"
          fill={color}
        />
        <path
          d="M13.3088 11.2601C13.9314 11.2601 14.4362 10.7554 14.4362 10.1328C14.4362 9.51015 13.9314 9.00542 13.3088 9.00542C12.6862 9.00542 12.1814 9.51015 12.1814 10.1328C12.1814 10.7554 12.6862 11.2601 13.3088 11.2601ZM15.5612 10.1328C15.5612 11.3767 14.5527 12.3851 13.3088 12.3851C12.0649 12.3851 11.0564 11.3767 11.0564 10.1328C11.0564 8.88883 12.0649 7.88042 13.3088 7.88042C14.5527 7.88042 15.5612 8.88883 15.5612 10.1328Z"
          fill={color}
        />
        <path
          d="M7.42078 14.5078C7.42078 14.1971 7.67262 13.9453 7.98328 13.9453L18.8924 13.9453C19.203 13.9453 19.4549 14.1971 19.4549 14.5078C19.4549 14.8184 19.203 15.0703 18.8924 15.0703L7.98328 15.0703C7.67262 15.0703 7.42078 14.8184 7.42078 14.5078Z"
          fill={color}
        />
        <path
          d="M1.96643 14.5078C1.96643 14.1971 2.21827 13.9453 2.52893 13.9453L4.34711 13.9453C4.65777 13.9453 4.90961 14.1971 4.90961 14.5078C4.90961 14.8184 4.65777 15.0703 4.34711 15.0703L2.52893 15.0703C2.21827 15.0703 1.96643 14.8184 1.96643 14.5078Z"
          fill={color}
        />
        <path
          d="M6.29496 13.5102C5.67234 13.5102 5.1676 14.0149 5.1676 14.6375C5.1676 15.2602 5.67234 15.7649 6.29496 15.7649C6.91758 15.7649 7.42231 15.2602 7.42231 14.6375C7.42231 14.0149 6.91758 13.5102 6.29496 13.5102ZM4.0426 14.6375C4.0426 13.3936 5.05102 12.3852 6.29496 12.3852C7.5389 12.3852 8.54731 13.3936 8.54731 14.6375C8.54731 15.8815 7.5389 16.8899 6.29496 16.8899C5.05102 16.8899 4.0426 15.8815 4.0426 14.6375Z"
          fill={color}
        />
      </svg>
    </div>
  );
}


export default DesktopOfferList;
