import "./PanelDrawer.css";
import "animate.css";
import { useAppDispatch } from "../../app/store";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  addSubProcessSectionIds,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectInscribedSubjects,
  selectPinnedSubjectsList,
  selectSubcriptionProcessSectionIds,
  selectSubjectsQuotas,
  setDetailSubjectOffered,
  setLoadingRequestInTransit,
  setPinnedSubjectsList,
  subtractCalendarSubjectDemoCount,
} from "../../reduxSlices/subjectsSlice";
import { ICalendarSubjects, IDesktopCalendarSubjects, IOfferedSubjectSection } from "../../types";
import { FaUser } from "react-icons/fa6";
import { BsFillPinAngleFill } from "react-icons/bs";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getQuotaColor } from "../../utils";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { changeSection, reserveSection } from "../../Api/reservation";
import { selectConfirmationsFlag } from "../../reduxSlices/parametersSlice";
import Modal from "../Modal/Modal";
import { useEffect, useState } from "react";
import {
  selectInTransitInRquest,
  selectMobileFeatures,
  setInTransitInscriptionRequest,
} from "../../reduxSlices/globalFlagsSlice";
import { useToastError } from "../../hooks/useToast";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";
import { LiaSpinnerSolid } from "react-icons/lia";
import QuotaCountIcon from "../QuotaCountIcon/QuotaCountIcon";
import ButtonInscription from "../ButtonInscription/ButtonInscription";

interface IPropsPanelDrawer {
  subject: IOfferedSubjectSection;
}

const findProfeTitular = (subject: IOfferedSubjectSection) => {
  let profe;
  subject.subjectData.subjectsByType.forEach((cursada) => {
    cursada.subjectInfo.forEach((horario) => {
      if (horario.values.rol === "Profesor") {
        profe = horario.values.profesores[0];
      }
    });
  });
  return profe ? profe : "";
};

const PanelDrawer = ({ subject }: IPropsPanelDrawer) => {
  const dispatch = useAppDispatch();
  const student = useSelector(selectStudent);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const confirmationsEnabled = useSelector(selectConfirmationsFlag);
  const inTransitInscriptionRequet = useSelector(selectInTransitInRquest);
  const [sendInscription, setSendInscription] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const { prioridad } = useSelector(selectStudent);
  const [openModal, setOpenModal] = useState(false);

  const esInscripta = inscribedSubjects?.filter((curso) => curso.idCurso === subject.id).length ? true : false;

  const showError = useToastError();

  const calendarize = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === subject.id);
  const pinned = pinnedSubjectsList.indexOf(subject.id) > -1;
  const quota = subjectsQuotas[subject.id];
  const quotaQty = subjectsQuotas[subject.id].qty;

  useEffect(() => {
    if (inTransitInscriptionRequet && hasClick) {
      setSendInscription(true);
      setTimeout(() => {
        setHasClick(false);
        setSendInscription(false);
      }, 10000);
    }
  }, [inTransitInscriptionRequet]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    dispatch(setDetailSubjectOffered(null));
  };

  const handleToggleCalendarSubject = async (idCurso: number) => {
    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount());

      return;
    }

    let listado: IDesktopCalendarSubjects[] = [];

    dispatch(addCalendarSubjectDemoCount());

    subject.subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        const newDemo: IDesktopCalendarSubjects = {
          curso: subject.section,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: Number(subject.idMateria),
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0,
          tipoCursada: horario.values.tipoClase,
        };

        listado.push(newDemo);
      });
    });
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));
  };

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
  };

  const inscribeToSubject = async () => {
    try {
      setHasClick(true);
      dispatch(setInTransitInscriptionRequest(true));
      const data = subject.subjectData.reserveData;
      const results = await reserveSection({
        idC: data.idC,
        idS: data.idS,
        idA: data.idA,
        tim: data.tim,
        tic: data.tic,
        idPersona: student.id.toString(),
      });

      if (results.data.responseCode !== 200) {
        showError(results.data.responseMessage);
      } else {
        await dispatch(setLoadingRequestInTransit(true));
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      dispatch(setInTransitInscriptionRequest(false));
    }
  };

  const handleTryInscribe = async () => {
    if (confirmationsEnabled) {
    } else {
      inscribeToSubject();
    }
  };


  const tagsListingRender = (tags: string) => {
    const colors = ["#C47DDD", "#2277DA", "#209DAE", "#97D86C", "#FBC54F", "#FF7A30", "#FF7A30"];
    const list = tags.split(",").map((item) => item.trim());

    return list.map((tag, i) => <p style={{ background: colors[i < colors.length ? i : colors.length % i] }}>{tag}</p>);
  };

  const handleModal = (bool: boolean) => {
    console.log(bool);
  };

   const handlTryChangeSection = async (idCurso: number) => {
     setOpenModal(false);
     dispatch(addSubProcessSectionIds(idCurso));
     const result = await changeSection({
       tic: subject.subjectData.reserveData.tic,
       tim: subject.subjectData.reserveData.tim,
       idC: subject.subjectData.reserveData.idC,
       idA: subject.subjectData.reserveData.idA,
       idS: subject.subjectData.reserveData.idS,
     });

     if (result.data.responseCode !== 200) {
       showError(result.data.responseMessage);
     } else {
     }
   };

  return (
    <div>
      <div id="panel-drawer" className="desktop-panel-drawer animate__animated animate__slideInRight animate__faster">
        {/* ------------------------------ PANEL CONTENT ----------------------------- */}
        <div className="desktop-panel-drawer-content">
          {esInscripta && <h5 className="confirmed-subject-title">INSCRIPCIÓN CONFIRMADA</h5>}
          <div className="panel-drawer-description-title">
            {prioridad && !esInscripta && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <QuotaCountIcon
                  qty={quotaQty}
                  subjectData={subject.subjectData}
                  availability={quota.availability}
                  idMateria={subject.idMateria}
                  quota={quota}
                />
              </div>
            )}
            <div className="panel-drawer-titles">
              <h2 className="panel-drawer-title-name">{subject.section}</h2>
              <p className="panel-drawer-title-profesor">{findProfeTitular(subject) || "A designar"}</p>
            </div>
            <SlIcon
              name="x-lg"
              style={{ color: "black", minWidth: "20px", height: "20px", cursor: "pointer" }}
              onClick={handleClose}
            ></SlIcon>
          </div>
          <div className="drawe-content-scroll">
            <ProfessorAndSubjectHours showBorderTop showRol subjectData={subject.subjectData} isDrawerContent={true} />

            {subject.condicionAprob && (
              <div className="panel-drawer-content-rows">
                <h3 className="panel-drawer-h3-title">Condiciones de aprobación del Curso</h3>
                <p>{subject.condicionAprob}</p>
              </div>
            )}

            {(subject.year || subject.creditos || subject.modalidad) && (
              <div className="panel-drawer-content-rows row-modalidad">
                {subject.year && (
                  <div className="modalidad-inner-col">
                    <h4>Año</h4>
                    <p>{subject.year}</p>
                  </div>
                )}
                {subject.creditos > 0 && (
                  <div className="modalidad-inner-col">
                    <h4>Créditos</h4>
                    <p>{subject.creditos}</p>
                  </div>
                )}
                {subject.modalidad && (
                  <div className="modalidad-inner-col">
                    <h4>Modalidad</h4>
                    <p>{subject.modalidad}</p>
                  </div>
                )}
              </div>
            )}

            {subject.tag && (
              <div className="panel-drawer-content-rows">
                <h4>Campo menor</h4>
                <div className="panel-tags-list-container">{tagsListingRender(subject.tag)}</div>
              </div>
            )}

            {subject.obs && (
              <div className="panel-drawer-content-rows">
                <h4>Observaciones</h4>
                <p>{subject.obs}</p>
              </div>
            )}

            {subject.catalogo && (
              <div className="panel-drawer-content-rows">
                <h3 className="panel-drawer-h3-title">Descripción del curso</h3>
                <p className="panel-drawer-description-box">{subject.catalogo}</p>
                {subject.link && (
                  <a className="panel-drawer-link" href={subject.link} target="_blank">
                    Ver más contenido sobre el curso
                  </a>
                )}
              </div>
            )}
          </div>

          {!esInscripta && (
            <div id="row-actions">
              <div className="panel-actions-row">
                <div className="panel-drawer-circle-buttons">
                  <button
                    className={`panel-drawer-actions ${pinned ? "btn-pin" : ""}`}
                    title="Fijar materia"
                    onClick={() => handlePinSubject(subject.id, pinned)}
                  >
                    <BsFillPinAngleFill />
                  </button>
                  {student.mostrarHorarios && (
                    <button
                      className={`panel-drawer-actions ${calendarize ? "btn-calendar" : ""}`}
                      title="Visualizar en calendario"
                      onClick={() => handleToggleCalendarSubject(subject.id)}
                    >
                      <SlIcon name="calendar"></SlIcon>
                    </button>
                  )}
                </div>
                {student.prioridad && quota && quota.availability === 0 && (
                  <button className="desktop-action-add-noquota" disabled>
                    <SlIcon name="x"></SlIcon>
                    Sin cupo
                  </button>
                )}
                {student.prioridad && quota && quota.availability > 0 && (
                  <ButtonInscription
                    id={subject.id}
                    idMateria={subject.idMateria}
                    handlTryReserve={handleTryInscribe}
                    setOpenModal={setOpenModal}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {openModal && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          message=""
          subMessage={`En caso de que el cambio NO sea posible, tu cupo original en ${subject
            .section} sera reservado. De esta forma, no perderas tu cupo para cursar la materia.`}
          topBorderWarn={false}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => setOpenModal(false),
            color: "#00C4B3",
          }}
          okButton={{
            label: "Continuar",
            buttonFn: () => {
              handlTryChangeSection(Number(subject.id));
            },
            color: "#00C4B3",
          }}
        />
      )}
    </div>
  );
};

export default PanelDrawer;
