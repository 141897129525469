import { useDispatch, useSelector } from "react-redux";
import { deleteUnsubProcessSectionId, selectUnsubProcessSectionIds } from "../../reduxSlices/subjectsSlice";
import { LiaSpinnerSolid } from "react-icons/lia";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useEffect } from "react";

interface IProps {
  handleTrashSubject: (s: any) => void;
  idCurso: number;
  subject: any;
}
export default function CardInscribedSubject({ handleTrashSubject, idCurso, subject }: IProps) {
  const dispatch = useDispatch();
  const unsubProcessSectionIds = useSelector(selectUnsubProcessSectionIds);

  const isProcessing = unsubProcessSectionIds.indexOf(Number(idCurso)) !== -1;

  useEffect(() => {
    return () => {
      if (isProcessing) {
        dispatch(deleteUnsubProcessSectionId(idCurso));
      }
    };
  }, []);

  return (
    <button
      onClick={() => handleTrashSubject(subject)}
      className="desktop-btn-unsuscribe-subject"
      disabled={isProcessing}
    >
      {isProcessing ? (
        <>
          <LiaSpinnerSolid className="spinner-animation" /> <span className="mobile-no-text">Procesando</span>
        </>
      ) : (
        <>
          <SlIcon name="dash-lg" />
          <span className="mobile-no-text">Desinscribirme</span>
        </>
      )}
    </button>
  );
}
