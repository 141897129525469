import axios from "axios";
import { IOfferedReserveData, OfertaAlumnoResponse } from "../types";

export const getMateriasAlumno = async (
  idPersona: string
): Promise<{ data: OfertaAlumnoResponse["oferta"]; status: number }> => {
  const { data, status } = await axios.post(`/api/materias?r=${Math.random().toString()}`, { idPersona });
  const response: OfertaAlumnoResponse = data;

  const { invalidando, venciendo } = response;
  if (invalidando) {
    console.log("%cOFERTA INVALIDADA", "color: DarkGreen; text-decoration: underline; background: PaleGreen;");
  }
  setTimeout(() => {
    if (venciendo) {
      console.log(
        "%cOFERTA VENCIDA, REGENERACIÓN EN PROCESO... ⏳",
        "color: DarkGreen; text-decoration: underline; background: PaleGreen;"
      );
    }
  }, 3000);

  return { data: response.oferta, status };
};

export const getMateriasInscriptasAlumno = async (idPersona: number) => {
  const { data, status } = await axios.post(`/api/materiasInscriptas?r=${Math.random().toString()}`, {
    id: idPersona,
  });
  return { data, status };
};

export const getMateriasFijadasAlumno = async (idPersona: number) => {
  const { data, status } = await axios.post(`/api/materiasFijadas?r=${Math.random().toString()}`, {
    id: idPersona,
  });
  return { data, status };
};

export const toggleMateriaFijadaAlumno = async (idPersona: number, cursoId: number) => {
  const { data, status } = await axios.post(`/api/toggleMateriasFijadas?r=${Math.random().toString()}`, {
    id: idPersona,
    idCurso: cursoId,
  });
  return { data, status };
};

export const checkCalendarizeSubject = async (reserveData: IOfferedReserveData) => {
  const { data, status } = await axios.post(`/api/validarInscripcion`, {
    idA: reserveData.idA,
    idC: reserveData.idC,
    idS: reserveData.idS,
    tim: reserveData.tim,
    tic: reserveData.tic,
  });
  return { data, status };
};
